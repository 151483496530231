<template>
  <div class="FiltersLocationNode">
    <base-checkbox v-model="selected">
      <node-name class="ml-1"
                 :node="node"
                 :inline="true" />
    </base-checkbox>
  </div>
</template>

<script>
import NodeName from '@/components/Node/NodeName.vue'

export default {
  components: {
    NodeName
  },

  props: {
    node: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      selected: true
    }
  },

  watch: {
    selected () {
      this.$emit('toggle', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
  .FiltersLocationNode {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .base-checkbox {
      margin: 0;
    }
  }
</style>
