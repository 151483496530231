<template>
  <div class="FiltersPagination">
    <div class="label">
      Viewing <span class="index">{{ from }}</span> - <span class="index">{{ to > total ? total : to }}</span>
      of <span class="index">{{ total }}</span>
    </div>
    <base-paginate :total="total"
                   :skip="skip"
                   :take="take"

                   @prev="$emit('prev')"
                   @next="$emit('next')" />
  </div>
</template>

<script>
export default {
  props: {
    total: {
      required: true,
      type: Number
    },

    skip: {
      required: true,
      type: Number
    },

    take: {
      required: true,
      type: Number
    }
  },

  computed: {
    from () {
      return this.skip
    },

    to () {
      return this.skip + this.take
    }
  }
}
</script>

<style lang="scss" scoped>
  .FiltersPagination {
    display: flex;
    align-items: center;

    .label {
      font-size: 12px;
      font-weight: 600;
      color: #b2b6c3;

      .index {
        font-weight: 700;
      }
    }

    .arrows {
      display: flex;
      justify-content: space-between;
      width: 76px;
      margin-left: 30px;

      .arrow {
        @include flex-center;

        padding: 8px;
        cursor: pointer;

        border-radius: 4px;
        background-color: #f6f5f7;
        transition: 0.2s all;

        &:hover {
          background-color: darken(#f6f5f7, 5%);
        }
      }
    }
  }
</style>
