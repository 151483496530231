<template>
  <div class="MonitorCheckList" v-if="monitor">
    <template v-if="!monitor.latest_check">
      <base-alert type="info">This monitor does not have any failed checks yet</base-alert>
    </template>
    <template v-else>
      <div class="top">
        <div class="title">Failed Checks</div>
      </div>
      <failed-checks :monitor="monitor" />
    </template>
  </div>
</template>

<script>
import FailedChecks from '@/components/Monitor/MonitorFailedChecks/MonitorFailedChecks.vue'
import monitorsApi from '@/api/monitorsApi.js'

export default {
  components: {
    FailedChecks
  },

  metaInfo: {
    title: 'Monitor Checks'
  },

  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null
    }
  },

  async created () {
    this.monitor = await monitorsApi.find(this.monitorId)
  }
}
</script>

<style lang="scss" scoped>
  .MonitorCheckList {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }

      .hint {
        color: #777;
      }
    }
  }
</style>
