<template>
  <div class="MonitorChecks">
    <div class="table-wrapper">
      <checks-filters :monitor="monitor"
                      :total-records="total"
                      @update="onFiltersUpdate" />

      <div class="loader" v-if="loading">
        <base-loader-dual-ring />
      </div>

      <table class="table" v-if="!loading">
        <colgroup>
          <col width="25%">
          <col width="25%">
          <col width="25%">
          <col width="25%">
        </colgroup>
        <thead>
          <tr>
            <th>Started at</th>
            <th>Request Time</th>
            <th>Response Code</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="check in checks"
              :key="check.id">
            <td>
              {{ check.created_at | shortDate }}
              <router-link :to="getCheckRoute(check)" class="details-link">View Details</router-link>
            </td>
            <td>
              <template v-if="check.response_time">
                {{ check.response_time | responseTime }} sec
              </template>
            </td>
            <td>
              <status-code :check="check"
                           :code="check.response.code"
                           :reason-phrase="check.response.reason"/>
            </td>
            <td>
              <node-name :node="check.node" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import StatusCode from '@/components/MonitorCheck/MonitorCheckStatus.vue'
import NodeName from '@/components/Node/NodeName.vue'
import ChecksFilters from './Filters/Filters.vue'

export default {
  components: {
    StatusCode,
    NodeName,
    ChecksFilters
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      checks: [],
      total: 0,

      loading: false
    }
  },

  methods: {
    onFiltersUpdate (filters) {
      this.loadChecks({
        is_successful: false,
        ...filters
      })
    },

    async loadChecks (params) {
      this.loading = true

      const response = await monitorsApi.getChecks(this.monitor.id, params)

      this.checks = response.items
      this.total = response.meta.total

      this.loading = false
    },

    getCheckRoute (check) {
      return {
        name: 'monitors.single.checks.single',
        params: {
          id: check.monitor_id,
          checkId: check.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorChecks {
    .table-wrapper {
      position: relative;
      min-height: 500px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .125);
      border-radius: 4px;
    }

    .loader {
      @include flex-center;

      width: 100%;
      height: 100%;

      margin-top: 60px;
    }

    td.details {
      padding: 0;
    }

    .details-link {
      opacity: 0;
      display: inline-flex;
      padding: 10px;
      transition: 0.1s all;
      font-size: 12px;
    }

    tr:hover .details-link {
      opacity: 1;
    }
  }
</style>
