<template>
  <div class="Filters">
    <div class="left">
      <filters-response-code class="response-code"
                             @update="onResponseCodesUpdate"/>
      <filters-location class="location ml-4"
                        @update="onNodesUpdate" />
    </div>
    <div class="right">
      <filters-pagination :total="totalRecords"
                          :take="filters.take"
                          :skip="filters.skip"

                          @prev="onPrev"
                          @next="onNext" />
    </div>
  </div>
</template>

<script>
import FiltersLocation from './FiltersLocation.vue'
import FiltersResponseCode from './FiltersResponseCode.vue'
import FiltersPagination from './FiltersPagination.vue'

export default {
  components: {
    FiltersLocation,
    FiltersResponseCode,
    FiltersPagination
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },

    totalRecords: {
      required: false,
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      filters: {
        monitor_id: this.monitor.id,
        response_codes: null,
        nodes: null,
        skip: 0,
        take: 100
      }
    }
  },

  methods: {
    onResponseCodesUpdate (codes) {
      this.filters.response_codes = codes.filter(code => code.selected).map(code => code.type)
    },

    onNodesUpdate (nodes) {
      this.filters.nodes = nodes.map(node => node.id)
    },

    onNext () {
      this.filters.skip += this.filters.take
    },

    onPrev () {
      this.filters.skip -= this.filters.take
    }
  },

  watch: {
    filters: {
      handler: function () {
        this.$emit('update', this.filters)
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    ...mapState('nodes', [
      'nodes'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .Filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px;

    .left {
      display: flex;
      align-items: center;
    }

    .left {
      flex: 1 0;
    }

    .right {

    }
  }
</style>
