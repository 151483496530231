<template>
  <div class="FiltersResponseCode">
    <div class="label">Response Code</div>

    <div class="toggle"
         :class="{ open }"
         ref="link">
      <div class="value">Any Code</div>
      <icon-dropdown width="14" height="14" class="icon icon-dropdown" />
    </div>

    <div ref="dropdown" class="dropdown">
      <div class="title">HTTP Response Codes</div>
      <base-checkbox label="1xx Informational Response"
                     v-model="codes[0].selected" />
      <base-checkbox label="2xx Success"
                     v-model="codes[1].selected" />
      <base-checkbox label="3xx Redirection"
                     v-model="codes[2].selected" />
      <base-checkbox label="4xx Client Errors"
                     v-model="codes[3].selected" />
      <base-checkbox label="5xx Server Errors"
                     v-model="codes[4].selected" />
      <base-checkbox label="No Response"
                     v-model="codes[5].selected" />
        <a href="https://en.wikipedia.org/wiki/List_of_HTTP_status_codes"
           class="hint"
           target="_blank">
          What do they mean?
          <icon-external-link class="ml-2 mb-1"
                              width="14"
                              height="14"
                              color="#444" />
        </a>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  data () {
    return {
      tippyInstance: null,
      open: false,

      codes: [
        {
          type: '100',
          selected: true
        },
        {
          type: '200',
          selected: true
        },
        {
          type: '300',
          selected: true
        },
        {
          type: '400',
          selected: true
        },
        {
          type: '500',
          selected: true
        },
        {
          type: 'no_response',
          selected: true
        }
      ]
    }
  },

  mounted () {
    this.setTippy()
  },

  methods: {
    setTippy () {
      this.tippyInstance = tippy(this.$refs.link, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    }
  },

  watch: {
    codes: {
      handler: function () {
        this.$emit('update', this.codes)
      },
      deep: true
    }
  },

  computed: {
    ...mapState('nodes', [
      'nodes'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .FiltersResponseCode {
    display: flex;
    align-items: center;

    .icon {
      height: 16px;
    }

    .toggle {
      display: flex;
      align-items: center;
      cursor: pointer;

      margin-left: 10px;
      padding: 6px 6px 6px 8px;
      border-radius: 4px;
      transition: 0.2s all ease;

      &:hover, &.open {
        background-color: #f6f5f7;
      }
    }

    .label {
      font-size: 11px;
      font-weight: 600;
      line-height: 1;
      opacity: .8;
      text-transform: uppercase;
      letter-spacing: .05em;
    }

    .value {
      font-weight: 600;
      font-size: 11px;
      line-height: 1;
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    .icon-dropdown {
      margin-left: 4px;
      margin-top: -2px;
    }

    .dropdown {
      padding: 10px;

      .title {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 14px;
      }

      .hint {
        display: inline-flex;
        align-items: center;

        .icon {
          margin-bottom: 2px;
        }
      }
    }
  }
</style>
