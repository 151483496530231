<template>
  <div class="FiltersLocation">
    <div class="label">Location</div>

    <div class="toggle"
         :class="{ open }"
         ref="link">
      <div class="value">Any Location</div>
      <icon-dropdown width="14" height="14" class="icon icon-dropdown" />
    </div>

    <div ref="dropdown" class="dropdown">
      <div class="title">Location List</div>
      <node v-for="node in nodesCopy"
            :key="node.id"
            :node="node"
            @toggle="onLocationToggle(node, $event)" />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import Node from './FiltersLocationNode.vue'

export default {
  components: {
    Node
  },

  data () {
    return {
      tippyInstance: null,
      nodesCopy: [],
      open: false
    }
  },

  created () {
    this.nodesCopy = this.nodes.map(node => ({
      ...node,
      selected: true
    }))
  },

  mounted () {
    this.setTippy()
  },

  methods: {
    setTippy () {
      this.tippyInstance = tippy(this.$refs.link, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    },

    onLocationToggle (node, selected) {
      let toggledNode = this.nodesCopy.find(n => n.id === node.id)
      toggledNode.selected = selected

      this.$emit('update', this.nodesCopy.filter(node => node.selected))
    }
  },

  computed: {
    ...mapState('nodes', [
      'nodes'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .FiltersLocation {
    display: flex;
    align-items: center;

    .icon {
      height: 16px;
    }

    .toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 10px;
      padding: 6px 6px 6px 8px;
      border-radius: 4px;
      transition: 0.2s all ease;

      &:hover, &.open {
        background-color: #f6f5f7;
      }
    }

    .label {
      font-size: 11px;
      font-weight: 700;
      line-height: 1;
      color: #b2b6c3;
      text-transform: uppercase;
    }

    .value {
      font-weight: 700;
      font-size: 11px;
      line-height: 1;
      text-transform: uppercase;
    }

    .icon-dropdown {
      margin-left: 4px;
    }

    .dropdown {
      width: 300px;
      padding: 10px;

      .title {
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 14px;
        letter-spacing: 0.005em;
      }
    }
  }
</style>
